<template>
    <v-menu
        offset-y
        min-width="100"
        transition="slide-y-transition"
        :close-on-content-click="canClose"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                depressed
                :color="color"
                :text="isText"
                v-bind="attrs"
                v-on="on"
            >
                <span class="">{{ label }}</span>
                <v-icon small color="purple">
                    {{ icon }}
                </v-icon>
            </v-btn>
        </template>

        <slot name="listItems" />
    </v-menu>
</template>

<script>
export default {
    name: "DropdownMenu",

    props: {
        icon: {
            required: true,
            type: String,
        },
        label: {
            required: false,
            type: String,
            default: '',
        },
        color: {
            required: false,
            type: String,
            default: "primary",
        },
        isText: {
            required: true,
            type: Boolean,
        },
        canClose: {
            required: true,
            type: Boolean,
        },
    },
};
</script>

<style lang="scss" scoped>
.profile-bnt {
    border-radius: 0.16em;
    font-size: 1.2em;
    font-weight: 600;
    padding: 0.8em;
}
</style>
