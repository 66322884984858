<template>
    <v-container class="my-10">
        <div class="d-flex align-center">
          <v-icon normal left color="purple">fas fa-lightbulb</v-icon>
          <h2 class="main-heading primary--text">Innovation Demonstrators</h2>
        </div>
        <base-card>
            <template #content>
                <v-card-text class="flex-col-between fill-height">
                    <v-data-table
                        :headers="headers"
                        :items="items"
                        :items-per-page="8"
                        :loading="handleDemonstratorStatus_Pending"
                        :loading-text="$t(`Admin.dataTable.loadingText`)"
                        :no-data-text="$t(`Admin.dataTable.noDataText`)"
                        :no-results-text="$t(`Admin.dataTable.noResultsText`)"
                        :page.sync="page"
                        hide-default-footer
                        disable-sort
                    >
                        <template v-slot:top>
                            <v-row class="mb-4">
                                <v-col cols="12" class="d-flex justify-end">
                                    <v-btn
                                        depressed
                                        dense
                                        outlined
                                        link
                                        :to="{
                                            name: 'CreateEditDemonstrator',
                                        }"
                                        color="primary"
                                        class="pa-7"
                                    >
                                        <v-icon size="14" class="mr-2">
                                            fa-solid fa-plus
                                        </v-icon>
                                        {{ $t(`Admin.demonstrator.createNew`) }}
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </template>

                        <template #[`item.description`]="{ item }">
                            <div class="truncate">
                                {{ item.description.replace(/<[^>]+>/g, '') }}
                            </div>
                        </template>

                        <template #[`item.actions`]="{ item }">
                            <dropdown-menu
                                :is-text="true"
                                :can-close="true"
                                icon="fas fa-ellipsis-v"
                            >
                                <template #listItems>
                                    <v-list>
                                        <v-list-item
                                            class="item-hover px-0"
                                            @click="editItem(item)"
                                        >
                                            <v-btn text color="accent darken-4">
                                                <v-icon size="12" class="mr-2">
                                                    fa-solid fa-pen
                                                </v-icon>
                                                <span class="table-menu-item">{{
                                                    $t(
                                                        `Admin.dataTable.tableActions.edit`
                                                    )
                                                }}</span>
                                            </v-btn>
                                        </v-list-item>
                                        <v-list-item
                                            class="item-hover px-0"
                                            @click="deleteItem(item)"
                                        >
                                            <v-btn text color="error lighten-1">
                                                <v-icon size="12" class="mr-2">
                                                    fa-solid fa-trash
                                                </v-icon>
                                                <span class="table-menu-item">{{
                                                    $t(
                                                        `Admin.dataTable.tableActions.delete`
                                                    )
                                                }}</span>
                                            </v-btn>
                                        </v-list-item>
                                    </v-list>
                                </template>
                            </dropdown-menu>
                        </template>
                    </v-data-table>
                    <div v-if="pageCount > 1">
                        <v-divider class="mb-4"></v-divider>

                        <div class="text-center table-pagination">
                            <v-pagination
                                v-model="page"
                                class="table-pagination"
                                :length="pageCount"
                                :total-visible="7"
                                elevation="0"
                            ></v-pagination>
                        </div>
                    </div>
                </v-card-text>
            </template>
        </base-card>
        <confirm-modal :open="openModal" :close.sync="openModal">
            <template #content>
                <span class="modal-content">
                    {{ $t(`Admin.demonstrator.confirmTxt`) }}
                </span>
            </template>
            <template #action>
                <v-btn
                    depressed
                    dense
                    color="error lighten1"
                    class="mr-2"
                    :loading="confirmStatus_Pending"
                    @click="confirm()"
                >
                    {{ $t(`Admin.modal.confirm`) }}
                </v-btn>
            </template>
        </confirm-modal>
    </v-container>
</template>

<script>
import { apiStatus } from "@/api/constants/apiStatus";
import { apiStatusComputed } from "@/api/helpers/computedApiStatus";
import { withAsync } from "@/helpers/withAsync";
import { getAllDemonstrators, deleteDemonstrator } from "@/api/adminApi.js";
import BaseCard from "@/components/base/BaseCard.vue";
import DropdownMenu from "./components/DropdownMenu.vue";
export default {
    name: "DIH",

    components: {
        BaseCard,
        DropdownMenu,
        ConfirmModal: () => import("@/components/ConfirmModal.vue"),
    },

    data() {
        return {
            items: [],
            page: 1,
            pageCount: 0,
            openModal: false,
            itemToDelete: {},
            handleDemonstratorStatus: apiStatus.Idle,
            confirmStatus: apiStatus.Idle,
        };
    },

    computed: {
        ...apiStatusComputed(["handleDemonstratorStatus", "confirmStatus"]),
        headers() {
            return [
                { text: "Id", value: "id" },
                {
                    text: this.$t(`Admin.dataTable.headers.title`),
                    value: "title",
                },
                {
                    text: this.$t(`Admin.dataTable.headers.description`),
                    value: "description",
                },
                {
                    text: this.$t(`Admin.dataTable.headers.user`),
                    value: "user.name",
                },
                {
                    text: this.$t(`Admin.dataTable.headers.actions`),
                    value: "actions",
                    sortable: false,
                },
            ];
        },
    },

    methods: {
        async handleDemonstrator() {
            this.handleDemonstratorStatus = apiStatus.Pending;

            const payload = {
                page: this.page,
                items: 6,
            };

            const { response, error } = await withAsync(
                getAllDemonstrators,
                payload
            );

            if (error) {
                this.handleDemonstratorStatus = apiStatus.Error;
                return;
            }

            this.items = response.data.data;
            this.pageCount = response.data.last_page;
            this.handleDemonstratorStatus = apiStatus.Success;
        },

        async confirm() {
            this.confirmStatus = apiStatus.Pending;
            const payload = {
                id: this.itemToDelete.id,
            };

            const { response, error } = await withAsync(
                deleteDemonstrator,
                payload
            );

            if (error) {
                this.confirmStatus = apiStatus.Error;
                return;
            }
            this.confirmStatus = apiStatus.Success;
            this.openModal = false;
            this.handleDemonstrator();
        },

        editItem(item) {
            this.$router.push({
                name: "CreateEditDemonstrator",
                params: { id: item.id },
            });
        },

        deleteItem(item) {
            this.openModal = true;
            this.itemToDelete = item;
        },
    },

    watch: {
        page(newVal) {
            this.handleDemonstrator();
        },
    },

    created() {
        this.handleDemonstrator();
    },
};
</script>

<style lang="scss" scoped>
.table-menu-item {
    font-size: 1.1em;
    font-weight: 500;
    letter-spacing: 0.06em;
}
</style>
