import { api, apiObject } from "./api";

const URLS = {
    innovationFields: "innovation-fields",
    services: "services",
    technologyFields: "technology-fields"
};

export const getInnovations = () => {
    return api.get(URLS.innovationFields);
};

export const getServices = () => {
    return api.get(URLS.services);
};
export const getTechnologyFields = () => {
    return api.get(URLS.technologyFields);
};