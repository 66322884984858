!<template>
    <div>
        <!-- <slot name="content" /> -->
        <div
         v-if="newVal && !$v.inputValue.$dirty"
         class="d-flex justify-center mx-0 mt-2 mb-8 pa-md-4 px-6"
        >
          <iframe
            width="100%"
            height="350px" 
            :src="applyURL()"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen 
          />
          <v-btn
           class="remove-media"
           icon
           depressed
           @click="removeURL()"
          >
           <v-icon color="warning">
              fa fa-times
           </v-icon>
          </v-btn>
        </div>
        <v-text-field
         v-else
         v-model.trim="inputValue"
         :prepend-inner-icon="icons"
         :append-outer-icon="deleteIcon"
         outlined
         :dark="selectTheme"
         color="primary"
         :label="label"
         :disabled="disabled"
         :error-messages="inputValueErrors"
         @click:append-outer="
         deleteField();
         "
         @input="
            $v.inputValue.$touch();
            emit();
        "
        @blur="
            $v.inputValue.$touch();
            emit();">
        </v-text-field>
    </div>
</template>

<script>
import { url, required } from "vuelidate/lib/validators";

export default {
    name: "TextInput",

    validations: {
        inputValue: {
            url,
            required
        },
    },

    props: {
        disabled: {
            type: Boolean,
            required: false,
        },
        currentValue: {
            type: String,
            required: false,
            default: "",
        },
        label: {
            required: true,
            type: String,
        },
        icons: {
            required: false,
            type: String,
            default: ""
        },
        deleteIcon: {
            required: false,
            type: String,
            default: ""
        },
        selectTheme: {
            required: false,
            type: Boolean,
            default: false,
        }
    },

    data() {
        return {
            inputValue: "",
            newVal: this.currentValue || null,
        };
    },

    computed: {
        inputValueErrors() {
            const errors = [];
            if (!this.$v.inputValue.$dirty) return errors;
            !this.$v.inputValue.url &&
                errors.push(this.$i18n.t(`ErrorMessages.validUrl`));

            const regExp = /youtu(?:.*\/v\/|.*v\=|\.be\/)([A-Za-z0-9_\-]{11})/;
            // const regExp = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/watch\?v=([a-zA-Z0-9_-]+)/
            let isYoutubeUrl = regExp.test(this.currentValue || this.inputValue);

            if (isYoutubeUrl == false && this.inputValue != '') {
                errors.push('This is not a valid Youtube URL. You may try another one');
            }
            
            return errors;
        },
    },

    methods: {
        formatUrl(url) {
            const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/; 
            const mather = url.match(regExp);

            if (mather && mather[2].length == 11) {
                return mather[2];
            } else {
                return null;
            }
        },
        applyURL() {
            const urlID = this.formatUrl(this.currentValue);
            return `https://www.youtube.com/embed/${urlID}`;
        },
        emit() {
            this.$emit("update:input-value", this.inputValue);
            this.$emit("update:in-valid", this.$v.$invalid);
        },
        removeURL() {
            this.newVal = null;
            this.inputValue = null;
            // console.log('inputVal', this.newVal);
            // console.log('inputValue', this.inputValue);
            this.$emit("update:in-valid", this.$v.$invalid);
            this.$emit("delete-video", '');
        },
        deleteField() {
            this.$emit("delete-field");
        }
    },

    watch: {
        currentValue(newVal) {
            if (newVal) this.newVal = newVal;
        }
    },

    created() {
        if (this.currentValue) {
            this.inputValue = this.currentValue;
        }
    },
}
</script>