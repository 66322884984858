<template>
    <v-container class="my-10">
        <div class="d-flex">
            <v-btn
                icon
                color="accent darken-3"
                class="align-self-center"
                link
                :to="computedReturnRoute"
            >
                <v-icon class="mr-2"> fa-solid fa-chevron-left </v-icon>
            </v-btn>
            <h2 class="main-heading primary--text">
                {{ $t(`Admin.CreateEditDemonstrator.${heading}`) }}
            </h2>
        </div>
        <base-card>
            <template #content>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <text-input
                                :input-value.sync="formValues.title"
                                :current-value="sme.title"
                                :validation.sync="validationErrors"
                                :in-valid.sync="isTitleInalid"
                                :label="
                                    $t(
                                        `Admin.CreateEditDemonstrator.form.title`
                                    )
                                "
                            ></text-input>

                            <!-- <text-area
                                :input-value.sync="formValues.description"
                                :current-value="sme.description"
                                :in-valid.sync="isDescriptionInalid"
                                :label="
                                    $t(
                                        `Admin.CreateEditDemonstrator.form.description`
                                    )
                                "
                            ></text-area> -->
                            
                            <text-editor
                             v-model="formValues.description"
                             :current-value="sme.description"
                             :in-valid.sync="isDescriptionInalid"
                             :label="
                                $t(
                                  `Admin.CreateEditDemonstrator.form.description`
                                )"
                            >
                            </text-editor>
                            <p 
                             class="mb-7 mt-1 text-end text-caption"
                             >
                             <span :class="`${descriptionLength > 20000 ? 'error--text' : ''}`">
                                {{ descriptionLength }}
                             </span>/
                             <span>20000</span>
                            </p>

                            <upload-input
                                :image-url.sync="formValues.image_url"
                                :current-image="sme.image_url"
                                :in-valid.sync="isImageUrlInalid"
                            ></upload-input>
                            <v-row>
                                <v-col cols="12" md="6">
                                    <select-input
                                        :input-value.sync="
                                            formValues.innovation_fields
                                        "
                                        :current-value="sme.innovation_fields"
                                        :in-valid.sync="isInnovationInalid"
                                        :items="innovations"
                                        :label="
                                            $t(
                                                `Admin.CreateEditDemonstrator.form.innovations`
                                            )
                                        "
                                    ></select-input>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <select-input
                                        :input-value.sync="
                                            formValues.technology_fields
                                        "
                                        :current-value="sme.technology_fields"
                                        :items="technologies"
                                        :in-valid.sync="isTechnologyInalid"
                                        :label="
                                            $t(
                                                `Admin.CreateEditDemonstrator.form.technologies`
                                            )
                                        "
                                    ></select-input>
                                </v-col>
                            </v-row>
                            <div class="d-flex flex-column align-center">
                                <v-col cols="12" md="6">
                                    <v-select
                                        :value="sme.project"
                                        outlined
                                        small-chips
                                        prepend-inner-icon="fa fa-video"
                                        :label="
                                            $t(
                                                `Admin.CreateEditDemonstrator.form.funding`
                                            )
                                        "
                                        :items="[
                                            'agrobofoodproject',
                                            'otherproject',
                                        ]"
                                        @input="setProject($event)"
                                    >
                                    </v-select>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    :md="sme.video_url ? 12 : 6" 
                                    :class="sme.video_url ? 'ml-6' : ''"
                                >
                                    <youtube-link-input
                                        :input-value.sync="formValues.video_url"
                                        :current-value="sme.video_url"
                                        icons="fa fa-video"
                                        :label="$t(`Admin.CreateEditDemonstrator.form.videoUrl`)"
                                    >
                                    </youtube-link-input>
                                </v-col>
                            </div>

                            <!-- <select-input
                                    :input-value.sync="formValues.services"
                                    :current-value="sme.services"
                                    :items="services"
                                    :in-valid.sync="isServicesInalid"
                                    :label="$t(`Admin.CreateEditDemonstrator.form.services`)"
                                ></select-input> -->

                            <!-- <select-input
                                    :input-value.sync="formValues.project"
                                    :current-value="sme.project"
                                    :items="[
                                        {
                                            name: 'agrobofoodproject',
                                            id: 'agrobofoodproject',
                                        },
                                        {
                                            name: 'otherproject',
                                            id: 'otherproject',
                                        },
                                    ]"
                                    :in-valid.sync="isProjectInalid"
                                    :multiple="false"
                                    :label="
                                        $t(
                                            `Admin.CreateEditDemonstrator.form.funding`
                                        )
                                    "
                                ></select-input> -->
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-divider class="my-5"></v-divider>
                 <v-row class="align-center mr-4">
                    <v-col
                     cols="12"
                     md="6"
                     sm="12"
                     class="d-flex justify-center justify-md-start mt-5"
                    >
                     <ul v-if="validationErrors.length != 0">
                        <div class="d-flex">
                            <v-icon small left color="warning">
                                fa fa-warning
                            </v-icon>
                            <h3 class="main-heading">
                                Form Failed to Submit!
                            </h3>
                        </div>
                        <li
                         v-for="(error, index) in validationErrors"
                         :key="index"
                         style="list-style: none;"
                         class="error--text text-start"
                        >
                           {{ error.toString() }}
                        </li>
                     </ul>
                    </v-col>
                    <v-col
                     cols="12"
                     md="6"
                     sm="12"
                     class="d-flex justify-center justify-md-end mt-5"
                    >
                      <v-card-actions class="mt-8">
                        <v-row>
                            <v-col class="d-flex justify-end">
                                <v-btn
                                    depressed
                                    color="primary"
                                    :disabled="disabled"
                                    :loading="
                                        hasParamsId()
                                            ? handleEditDemonstratorStatus_Pending
                                            : handleCreateDemonstratorStatus_Pending
                                    "
                                    @click="choseAction()"
                                >
                                <v-icon small left>fa-solid fa-save</v-icon>
                                    {{ actionMethodLabel }}
                                </v-btn>
                            </v-col>
                        </v-row>
                      </v-card-actions>
                    </v-col>
                </v-row>
            </template>
        </base-card>
    </v-container>
</template>

<script>
import { apiStatus } from "@/api/constants/apiStatus";
import { apiStatusComputed } from "@/api/helpers/computedApiStatus";
import { withAsync } from "@/helpers/withAsync";
import {
    getSingleDemonstrator,
    createDemonstrator,
    updateDemonstrator,
} from "@/api/adminApi.js";
import { getInnovations, getTechnologyFields } from "@/api/collectionsApi.js";

import TextInput from "@/components/Inputs/TextInput.vue";
import TextArea from "@/components/Inputs/TextArea.vue";
import UploadInput from "@/components/Inputs/UploadInput.vue";
import SelectInput from "@/components/Inputs/SelectInput.vue";
import YoutubeLinkInput from "../../components/Inputs/YoutubeLinkInput.vue";
import TextEditor from "../../components/Inputs/TextEditor.vue";

export default {
    name: "CreateEditDemonstrator",

    components: {
        TextInput,
        TextArea,
        UploadInput,
        SelectInput,
        YoutubeLinkInput,
        TextEditor,
    },

    data() {
        return {
            sme: {},
            innovations: [],
            services: [],
            technologies: [],
            mapOptions: {
                zoomControl: false,
                trackResize: true,
                maxBoundsViscosity: 1.0,
                doubleClickZoom: false,
            },
            descriptionLength: 0,
            formValues: {
                video_url: "",
            },
            validationErrors: [],
            isTitleInalid: this.hasParamsId() ? false : true,
            isDescriptionInalid: this.hasParamsId() ? false : true,
            isImageUrlInalid: this.hasParamsId() ? false : true,
            isInnovationInalid: this.hasParamsId() ? false : true,
            // isServicesInalid: this.hasParamsId() ? false : true,
            isTechnologyInalid: this.hasParamsId() ? false : true,
            isProjectInalid: this.hasParamsId() ? false : true,


            isYoutubeURLInvalid: this.hasParamsId() ? false : true,

            handleCreateDemonstratorStatus: apiStatus.Idle,
            handleEditDemonstratorStatus: apiStatus.Idle,
        };
    },

    computed: {
        ...apiStatusComputed([
            "handleCreateDemonstratorStatus",
            "handleEditDemonstratorStatus",
        ]),

        heading() {
            if (this.hasParamsId()) {
                return "editHeading";
            }
            return "createHeading";
        },
        actionMethodLabel() {
            return this.hasParamsId()
                ? "Update Demonstrator"
                : "Create Demonstrator";
        },
        //Form validation
        disabled() {
            if (this.hasParamsId()) {
                for (const key in this.formValues) {
                    if (this.formValues.hasOwnProperty(key)) {
                        return this.handleValidation();
                    }
                }
                return true;
            }
            return this.handleValidation();
        },

        computedReturnRoute() {
            return { name: "Demonstrators" };
        },
    },

    methods: {
        async handleSingleDemonstrator() {
            const payload = {
                id: this.$route.params.id,
            };

            const { response, error } = await withAsync(
                getSingleDemonstrator,
                payload
            );

            this.sme = response.data.data;
        },

        async handleInnovations() {
            const { response, error } = await withAsync(getInnovations);

            if (error) {
                return;
            }

            this.innovations = response.data.data;
        },

        async handleTechnology() {
            const { response, error } = await withAsync(getTechnologyFields);

            if (error) {
                return;
            }

            this.technologies = response.data.data;
        },

        async handleCreateDemonstrator() {
            this.handleCreateDemonstratorStatus = apiStatus.Pending;
            let data = new FormData();
            data.append("title", this.formValues.title);
            data.append("description", this.formValues.description);
            data.append("image_url", this.formValues.image_url);
            data.append("innovation_fields", this.formValues.innovation_fields);
            data.append("technology_fields", this.formValues.technology_fields);
            data.append("project", this.formValues.project);
            data.append("video_url", this.formValues.video_url);

            const { response, error } = await withAsync(
                createDemonstrator,
                data
            );

            if (error) {
                this.handleCreateDemonstratorStatus = apiStatus.Error;
                this.validationErrors = error.response.data.metadata.response_message;
                return;
            }
            this.handleCreateDemonstratorStatus = apiStatus.Success;
            this.$router.push({ name: "Demonstrators" });
        },
        async handleEditDemonstrator() {
            this.handleEditDemonstratorStatus = apiStatus.Pending;
            let data = new FormData();
            for (const [key, value] of Object.entries(this.formValues)) {
                data.append([key], value == null || '' ? null : value);
            }
            
            const payload = {
                id: this.$route.params.id,
                data: data,
            };

            const { response, error } = await withAsync(
                updateDemonstrator,
                payload
            );

            if (error) {
                this.handleEditDemonstratorStatus = apiStatus.Error;
                this.validationErrors = error.response.data.metadata.response_message;
                return;
            }
            this.handleEditDemonstratorStatus = apiStatus.Success;
            this.$router.push({ name: "Demonstrators" });
        },

        choseAction() {
            this.hasParamsId()
                ? this.handleEditDemonstrator()
                : this.handleCreateDemonstrator();
        },

        handleValidation() {
            if (this.isTitleInalid) {
                return true;
            }
            if (this.isDescriptionInalid) {
                return true;
            }
            if (this.isImageUrlInalid) {
                return true;
            }
            if (this.isInnovationInalid) {
                return true;
            }
            // if (this.isYoutubeURLInvalid) {
            //     return true;
            // }
            // if (this.isServicesInalid) {
            //     return true
            // }
            if (this.isTechnologyInalid) {
                return true;
            }

            if (this.isProjectInalid) {
                return true;
            }
            return false;
        },

        hasParamsId() {
            return this.$route.params.id ? true : false;
        },

        setProject(e) {
            this.formValues = { ...this.formValues, project: e };
            if (e) {
                this.isProjectInalid = false;
            }
        },
    },

    watch: {
        "formValues.description": {
            handler(newVal) {
                if(newVal) {
                    this.descriptionLength = newVal.length;
                }
            },
            deep: true,
        }
    },

    created() {
        this.apiStatus = apiStatus;
        if (this.$route.params.id) {
            this.handleSingleDemonstrator();
        }
        this.handleInnovations();
        // this.handleServices();
        this.handleTechnology();
    },
};
</script>

<style lang="scss" scoped>
.form-divider {
    border-width: 1px;
}
</style>
