var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"my-10"},[_c('div',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"align-self-center",attrs:{"icon":"","color":"accent darken-3","link":"","to":_vm.computedReturnRoute}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" fa-solid fa-chevron-left ")])],1),_c('h2',{staticClass:"main-heading primary--text"},[_vm._v(" "+_vm._s(_vm.$t(("Admin.CreateEditDemonstrator." + _vm.heading)))+" ")])],1),_c('base-card',{scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('text-input',{attrs:{"input-value":_vm.formValues.title,"current-value":_vm.sme.title,"validation":_vm.validationErrors,"in-valid":_vm.isTitleInalid,"label":_vm.$t(
                                    "Admin.CreateEditDemonstrator.form.title"
                                )},on:{"update:inputValue":function($event){return _vm.$set(_vm.formValues, "title", $event)},"update:input-value":function($event){return _vm.$set(_vm.formValues, "title", $event)},"update:validation":function($event){_vm.validationErrors=$event},"update:inValid":function($event){_vm.isTitleInalid=$event},"update:in-valid":function($event){_vm.isTitleInalid=$event}}}),_c('text-editor',{attrs:{"current-value":_vm.sme.description,"in-valid":_vm.isDescriptionInalid,"label":_vm.$t(
                              "Admin.CreateEditDemonstrator.form.description"
                            )},on:{"update:inValid":function($event){_vm.isDescriptionInalid=$event},"update:in-valid":function($event){_vm.isDescriptionInalid=$event}},model:{value:(_vm.formValues.description),callback:function ($$v) {_vm.$set(_vm.formValues, "description", $$v)},expression:"formValues.description"}}),_c('p',{staticClass:"mb-7 mt-1 text-end text-caption"},[_c('span',{class:("" + (_vm.descriptionLength > 20000 ? 'error--text' : ''))},[_vm._v(" "+_vm._s(_vm.descriptionLength)+" ")]),_vm._v("/ "),_c('span',[_vm._v("20000")])]),_c('upload-input',{attrs:{"image-url":_vm.formValues.image_url,"current-image":_vm.sme.image_url,"in-valid":_vm.isImageUrlInalid},on:{"update:imageUrl":function($event){return _vm.$set(_vm.formValues, "image_url", $event)},"update:image-url":function($event){return _vm.$set(_vm.formValues, "image_url", $event)},"update:inValid":function($event){_vm.isImageUrlInalid=$event},"update:in-valid":function($event){_vm.isImageUrlInalid=$event}}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('select-input',{attrs:{"input-value":_vm.formValues.innovation_fields,"current-value":_vm.sme.innovation_fields,"in-valid":_vm.isInnovationInalid,"items":_vm.innovations,"label":_vm.$t(
                                            "Admin.CreateEditDemonstrator.form.innovations"
                                        )},on:{"update:inputValue":function($event){return _vm.$set(_vm.formValues, "innovation_fields", $event)},"update:input-value":function($event){return _vm.$set(_vm.formValues, "innovation_fields", $event)},"update:inValid":function($event){_vm.isInnovationInalid=$event},"update:in-valid":function($event){_vm.isInnovationInalid=$event}}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('select-input',{attrs:{"input-value":_vm.formValues.technology_fields,"current-value":_vm.sme.technology_fields,"items":_vm.technologies,"in-valid":_vm.isTechnologyInalid,"label":_vm.$t(
                                            "Admin.CreateEditDemonstrator.form.technologies"
                                        )},on:{"update:inputValue":function($event){return _vm.$set(_vm.formValues, "technology_fields", $event)},"update:input-value":function($event){return _vm.$set(_vm.formValues, "technology_fields", $event)},"update:inValid":function($event){_vm.isTechnologyInalid=$event},"update:in-valid":function($event){_vm.isTechnologyInalid=$event}}})],1)],1),_c('div',{staticClass:"d-flex flex-column align-center"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{attrs:{"value":_vm.sme.project,"outlined":"","small-chips":"","prepend-inner-icon":"fa fa-video","label":_vm.$t(
                                            "Admin.CreateEditDemonstrator.form.funding"
                                        ),"items":[
                                        'agrobofoodproject',
                                        'otherproject' ]},on:{"input":function($event){return _vm.setProject($event)}}})],1),_c('v-col',{class:_vm.sme.video_url ? 'ml-6' : '',attrs:{"cols":"12","md":_vm.sme.video_url ? 12 : 6}},[_c('youtube-link-input',{attrs:{"input-value":_vm.formValues.video_url,"current-value":_vm.sme.video_url,"icons":"fa fa-video","label":_vm.$t("Admin.CreateEditDemonstrator.form.videoUrl")},on:{"update:inputValue":function($event){return _vm.$set(_vm.formValues, "video_url", $event)},"update:input-value":function($event){return _vm.$set(_vm.formValues, "video_url", $event)}}})],1)],1)],1)],1)],1),_c('v-divider',{staticClass:"my-5"}),_c('v-row',{staticClass:"align-center mr-4"},[_c('v-col',{staticClass:"d-flex justify-center justify-md-start mt-5",attrs:{"cols":"12","md":"6","sm":"12"}},[(_vm.validationErrors.length != 0)?_c('ul',[_c('div',{staticClass:"d-flex"},[_c('v-icon',{attrs:{"small":"","left":"","color":"warning"}},[_vm._v(" fa fa-warning ")]),_c('h3',{staticClass:"main-heading"},[_vm._v(" Form Failed to Submit! ")])],1),_vm._l((_vm.validationErrors),function(error,index){return _c('li',{key:index,staticClass:"error--text text-start",staticStyle:{"list-style":"none"}},[_vm._v(" "+_vm._s(error.toString())+" ")])})],2):_vm._e()]),_c('v-col',{staticClass:"d-flex justify-center justify-md-end mt-5",attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-card-actions',{staticClass:"mt-8"},[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"depressed":"","color":"primary","disabled":_vm.disabled,"loading":_vm.hasParamsId()
                                        ? _vm.handleEditDemonstratorStatus_Pending
                                        : _vm.handleCreateDemonstratorStatus_Pending},on:{"click":function($event){return _vm.choseAction()}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("fa-solid fa-save")]),_vm._v(" "+_vm._s(_vm.actionMethodLabel)+" ")],1)],1)],1)],1)],1)],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }